<template>
  <transition name="fade">
    <div class="popup popup__backdrop" @mousedown.self="closePopup">
      <div class="popup__content">
        <div class="reference-popup card">
          <div class="reference-popup__header card-header card-header-divider">
            <div v-if="profileId && referenceProfile">
              {{ referenceProfile.title }}
            </div>
            <div v-else-if="!profileId">Создание профиля</div>
            <button @click="closePopup" class="reference-popup__close-btn">
              <svg fill="#3d3d3d" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
                <path
                  d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
              </svg>
            </button>
          </div>
          <div class="card-body">
            <form v-if="
            !isPreloaded &&
              ( !profileId || !referenceProfile || referenceProfile.id == profileId)
            " action="#" @submit.prevent="sendProfile">
              <custom-input name="title" label="Название" :labelWidth="40" v-model="form.title" />
              <div  v-if="getRight('superadmin')">
                <custom-input disabled v-for="(value, key) in dictionary" type="number" :maxValue="150" :minValue="0"
                              :labelWidth="40" :key="key" :name="key" :label="value" v-model="form[key]" />
              </div>
              <button type="submit" class="btn btn-accent">Сохранить</button>
              <a v-if="getRight('superadmin') && referenceProfile?.log_link != null" v-bind:href="referenceProfile.log_link"
                download class="btn btn-accent">Скачать лог</a>
            </form>
            <div v-else class="popup-preloader">
              <preloader />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  computed,
  onBeforeMount,
  reactive,
  ref,
  toRef,
} from "@vue/runtime-core";
import { useStore } from "vuex";
import CustomInput from "../Forms/Fields/CustomInput.vue";
import Preloader from "../Technical/Preloader.vue";
import standartError from "../../mixins/standartError";
import useErrors from "../../mixins/useErrors";
import CustomCheckbox from "../Forms/Fields/CustomCheckbox.vue";
import useCheckRights from "../../mixins/useCheckRights";
export default {
  components: { CustomInput, Preloader, CustomCheckbox },
  name: "reference-popup",
  props: {
    profileId: {
      type: Number,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = useStore(),
      { setErrors, clearCustomErrors } = useErrors(),
      { getRight } = useCheckRights(),
      id = toRef(props, "profileId"),
      expert = ref(false),
      form = reactive({
        total: 0,
        domain_1: 0,
        domain_2: 0,
        branch_1: 0,
        branch_2: 0,
        branch_3: 0,
        branch_4: 0,
        section_1: 0,
        section_2: 0,
        section_3: 0,
        section_4: 0,
        section_5: 0,
        section_6: 0,
        section_7: 0,
        section_8: 0,
        section_9a: 0,
        section_9b: 0,
        title: " ",
      });
    const dictionary = {
      total: "Общий ЭИ",
      domain_1: "Опытный домен",
      domain_2: "Стратегический домен",
      branch_1: "Распознавание эмоций",
      branch_2: "Использование эмоций",
      branch_3: "Анализ эмоций",
      branch_4: "Управление эмоциями",
      section_1: "Раздел 1 (Распознавание эмоций в лицах)",
      section_2: "Раздел 2 (Применение эмоций в действиях и мышлении)",
      section_3: "Раздел 3 (Понимание смены эмоций)",
      section_4: "Раздел 4 (Регуляция своих эмоций)",
      section_5: "Раздел 5 (Изображения)",
      section_6: "Раздел 6 (Эмпатия)",
      section_7: "Раздел 7 (Смешивания)",
      section_8: "Раздел 8 (Влияние на эмоции других)",
      section_9a: "Раздел 9а (Распознавание эмоций в контексте ситуаций)",
      section_9b: "Раздел 9б (Понимание изменения эмоций в контексте ситуаций)",
    };
    const closePopup = () => {
      context.emit("close");
    };
    const isPreloaded = ref(false);

    onBeforeMount(() => {
      isPreloaded.value = true;
      if (id.value) {
        store.dispatch("analytics/getReferenceProfile", id.value).then(() => {
          for (let key in referenceProfile.value) {
              form[key] = referenceProfile.value[key];
          }
          expert.value = Boolean(referenceProfile.value.expert);
          isPreloaded.value = false;
        });
      }
    });

    const referenceProfile = computed(
      () => store.state.analytics.referenceProfile
    ),
      profile = computed(() => store.state.profile.profile);
    const sendProfile = () => {
      if (id.value) {
        store
          .dispatch("analytics/updateReferenceProfile", {
            id: id.value,
            ...formToNumbers(),
            company_id: profile.value.company.id,
          })
            .then(() => {
              store.dispatch("analytics/getReferenceProfiles")
        })
          .catch(standartError(setErrors, clearCustomErrors));
      } else {
        store
          .dispatch("analytics/createReferenceProfile", {
            ...formToNumbers(),
            company_id: profile.value.company.id,
            expert: expert.value,
          })
          .then(() => {
            closePopup();
          })
          .catch(standartError(setErrors, clearCustomErrors));
      }
      closePopup()
    };
    const formToNumbers = () => {
      const newForm = {};
      for (let key in form) {
        if (key != "title") newForm[key] = Number(form[key]);
      }
      newForm.title = form.title;
      return newForm;
    };

    return {
      closePopup,
      sendProfile,
      referenceProfile,
      dictionary,
      form,
      profile,
      formToNumbers,
      expert,
      getRight,
      isPreloaded
    };
  },
};
</script>

<style lang="scss" scoped>
.popup {
  &__content {
    width: 50%;
    height: 95%;
    overflow-y: auto;
    background-color: white;
  }

  @media (max-width: 1090px) {
    &__content {
      width: 75%;
      height: 95%;
    }
  }

  @media (max-width: 425px) {
    &__content {
      width: 100%;
      height: 100%;
    }
  }
}

.reference-popup {
  background-color: white;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  margin-bottom: 0;
  border-radius: 0;

  &__header {
    flex-direction: row;
  }

  &__close-btn {
    height: 40px;
    width: 40px;

    svg {
      max-width: 100%;
    }

    &:hover svg {
      fill: var(--hover-color);
    }
  }

  &__btns {
    margin-top: 25px;

    .btn:not(:last-child) {
      margin-right: 15px;
    }
  }

  @media (max-width: 425px) {
    &__header {
      margin: 0 20px;
    }
  }
}

.popup-preloader {
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>