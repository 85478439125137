<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Эталонные профили</div>
      </div>
      <div class="card-body">
        <references-table @openReference="openReference" />
      </div>
    </div>
  </div>
  <reference-popup :profileId="currentId" v-if="showPopup" @close="closePopup" :readOnly="readOnly"/>
</template>

<script>
import { ref } from '@vue/reactivity'
import ReferencesTable from "@/feature/references/tables/ReferencesTable.vue";
import ReferencePopup from '@/components/Popups/ReferencePopup.vue'

export default {
  components: { ReferencesTable, ReferencePopup },
  name: "references-list-page",
  setup() {
    const showPopup = ref(false), currentId = ref(0), readOnly = ref(true)

    const openReference = (data) => {
      currentId.value = data.id
      showPopup.value = true
      readOnly.value = data.readOnly
    }
    const closePopup = () => {
      currentId.value = 0
      showPopup.value = false
    }
 
    return {
      showPopup, currentId, openReference, closePopup, readOnly,
    }
  },
};
</script>

<style>
.btn-accent {
  margin-left: 15px;
}
</style>