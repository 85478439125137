export default function rusData() {
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const getRusData = (str) => {
    let dataParse = Date.parse(str);
    return new Intl.DateTimeFormat("ru-RU", options).format(dataParse);
  };

  return {
    getRusData,
  };
}